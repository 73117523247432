//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { flattenDeep } from 'lodash'

import { mapState } from 'vuex';

export default {
    data() {
        return {
            mods: [],
            choices: [],
            nonQuickStores: [
                "1284",
                "1022",
                "1019",
                "1071",
                "1006",
                "1055",
                "1225",
                "1220",
                "1021",
                "1216",
                "1008",
                "1060",
                "1184",
                "1221",
                "1051",
                "1018",
                "1084",
                "1059",
                "1097",
                "1290",
                "1258",
                "1041",
                "1042",
                "1002",
                "1116",
                "1223"
            ]
        }
    },
    // filters: {
    //   titleize(value) {
    //     const lower = value.toLowerCase()
    //     return lower.replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase())
    //   }
    // },
    props: {
        cat: {
            type: Object,
            default: () => { }
        },
        product: {
            type: Object,
            default: () => { }
        }
    },
    methods: {
        async addDefaultModifiers() {
            // const prd = this.$store.state.activeOrder.products.find((p) => {
            //   return p.product_id === this.product.id
            // })

            let product = {
                id: this.product.id,
                quantity: 1,
                choices: this.choices.map((c) => {
                    return {
                        id: c.id,
                        quantity: 1
                    }
                })
            }

            // if(prd) {
            //   const productToMatch = JSON.stringify({
            //     id: prd.product_id,
            //     choices: prd.choices.map((c) => {
            //       return {
            //         id: c.option_id,
            //         quantity: 1
            //       }
            //     })
            //   })
            //   const productToAdd = JSON.stringify({
            //     id: product.id,
            //     choices: this.choices.map((c) => {
            //       return {
            //         id: c.id,
            //         quantity: 1
            //       }
            //     })
            //   })
            //   console.log(productToMatch, productToAdd)

            //   // if (productToMatch === productToAdd) {
            //   //   console.log('Found In Cart')
            //   //   const productToUpdate = {
            //   //     id: prd.product_id,
            //   //     quantity: prd.quantity + 1,
            //   //     choices: prd.choices.map((c) => c.option_id).join(',')
            //   //   }

            //   //   const updatedOrder = await this.$api.updateInOrder(
            //   //     this.$store.state.activeOrder.id,
            //   //     prd.id,
            //   //     productToUpdate
            //   //   )
            //   //   if (updatedOrder) {
            //   //     this.$store.commit('setActiveOrder', updatedOrder)
            //   //   }
            //   //   this.$store.commit('setLoading', false)
            //   //   this.$store.commit('setGlobalCart', true)
            //   // } else {
            //       const order = await this.$api.addToOrder(
            //         this.$store.state.activeOrder.id,
            //         [product]
            //       )

            //       if (order) {
            //         console.log('setting order')
            //         this.$store.commit('setActiveOrder', order)
            //         this.$store.commit('setGlobalCart', true)
            //       }
            //       this.$store.commit('setLoading', false)
            //   // }
            // } else {
            // console.log('Product added')
            console.log(product)
            const order = await this.$api.addToOrder(
                this.$store.state.activeOrder.id,
                [product]
            )

            if (order) {
                console.log('setting order')
                this.$store.commit('setActiveOrder', order)
                this.$store.commit('setGlobalCart', true)
            }
            this.$store.commit('setLoading', false)
            // }
        },
        addToDataLayersGA(product, location, eventType, quantity) {
            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            // const index = dataLayer.findIndex(object => {
            //     return object.event == eventType
            // })
            // if (index > -1) {
            //     dataLayer.splice(index, 1)
            // }
            dataLayer.push({
                event: eventType,
                ecommerce: {
                    currency: "USD",
                    value: product.cost,
                    items: [{
                        item_id: product.sku,
                        item_name: product.name,
                        item_category: product.category,
                        location_id: location.id,
                        price: product.cost,
                        quantity: quantity
                    }]
                }
            });
        },
        async addToCart() {
            try {
                // this.$store.commit('setLoading', true)
                if (!this.$store.state.activeOrder) {
                    console.log('no active order to set');
                    const order = await this.$api.createOrder(
                        this.loc.menu_id
                    )
                    console.log('setting order')
                    this.$store.commit('setActiveOrder', order)
                }

                this.mods = await this.$api
                let prod = await this.$api
                    .getProduct(this.$store.state.activeOrder.menu_id, this.product.sku)
                    .then((m) => {
                        return m
                        // return this.mappedMods(m)
                    })

                // let choices = this.mappedMods(prod)
                let choices = this.quickAddChoices(prod.modifiers)
                const order = await this.$api.addToOrder(
                    this.$store.state.activeOrder.id,
                    [{
                        id: prod.id,
                        quantity: 1,
                        choices
                    }]
                )

                if (order) {
                    console.log('setting order')
                    this.$store.commit('setActiveOrder', order)
                    this.$store.commit('setGlobalCart', true)
                    this.addToDataLayersGA(this.product, this.location, "add_to_cart", 1)
                }
                this.$store.commit('setLoading', false)


                //FILTER OUT unmanadatory options
                // const opts = flattenDeep(
                //   this.mods
                //     .filter(
                //       (d) =>
                //         d.selects.mandatory ||
                //         d.action === 'Select Either or Both' ||
                //         d.options.length >> 1
                //     )
                //     .map((c) => {
                //       return c.options
                //     })
                // )

                // this.choices = opts

                // this.addDefaultModifiers()
            } catch (error) {
                this.$store.commit('setLoading', false)
                console.log(error)
            }
        },
        quickAddChoices(mods, choices = []) {
            mods.map(m => {
                if (m.selects.mandatory == true) {
                    let defaultOption = m.options.find(o => o.default == true)
                    if (!defaultOption) {
                        defaultOption = m.options[0]
                    }
                    choices.push({
                        id: defaultOption.id,
                        quantity: 1
                    })
                    if (defaultOption.modifiers) {
                        choices = this.quickAddChoices(defaultOption.modifiers, choices)
                    }
                }
            })
            return choices
        },
        mappedMods(m) {
            console.log(m)
            return m.modifiers.map((o) => {


                const isMultiOpts = o.selects.min >> 1
                const isMulti3Opts = o.selects.max === 3
                const isMulti2Opts = o.selects.max === 2
                const eitherOr = o.action === 'Select Either or Both'
                const sideone = o.name.match('Side One')
                const sidetwo = o.name.match('Side Two')
                const isSubMods = o.options && o.options.length > 0 && o.options[0] && o.options[0].modifiers ? true : false

                return {
                    action: o.action,
                    id: o.id,
                    name: o.name,
                    selects: o.selects,
                    options: o.options ?
                        isMultiOpts
                            ? [o.options[0], o.options[1]]
                            : isMulti2Opts
                                ? [o.options[0], o.options[1]]
                                : isMulti3Opts
                                    ? [o.options[0], o.options[1], o.options[2]]
                                    : sideone
                                        ? [o.options[0]]
                                        : sidetwo
                                            ? [o.options[1]]
                                            : isSubMods
                                                ? this.mappedMods(o.options[0]).map((s) => {
                                                    return s.options.map((opt) => opt)
                                                })
                                                : eitherOr
                                                    ? [o.options[0].modifiers[0].options[0]]
                                                    : [
                                                        o.options.find((d, i) => {
                                                            return d.default || i === 0
                                                        })
                                                    ]
                        : []
                }
            })
        }
    },
    computed: {
        ...mapState([
            'nonQuickAdds',
            'location'
        ]),
        qaExempts() {
            const ds = this.nonQuickAdds.disabled_skus
            const exempts = ds ? ds.replace(/\s/g, '').split(',') : []
            return exempts.map((d) => Number(d))
        },
        asItComes() {
            const aic = this.qaExempts.indexOf(this.product.sku) > -1
            return !aic
        },
        loc() {
            return this.$store.state.location
        },
        productImage() {
            if (this.product.images) {
                let imagepath = this.$store.state.activeMenu.imagepath
                this.product.images.map((i) => {
                    if (
                        (this.customize && i.groupname === 'desktop-customize') ||
                        i.groupname === 'desktop-customize'
                    ) {
                        return (imagepath += i.filename)
                    } else {
                        return ''
                    }
                })
                return imagepath
            } else {
                return 'https://a.storyblok.com/f/99717/x/55504e26d1/perkins-default-icon.svg'
            }
        }
    }
}
