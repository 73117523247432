//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import lottie from 'vue-lottie/src/lottie.vue'
import animationData from '~/assets/src/quickadd.json'
export default {
  data() {
    return {
      defaultOptions: { animationData, loop: false },
      animationSpeed: 1,
      anim: null,
      running:false
    }
  },
  components: { lottie },

  methods: {
    startAdd() {
      if (!this.running && this.anim) {
        this.running = true
        this.$emit('quickadd')
        this.anim.play()
        setTimeout(() => {
          this.anim.stop(0)
          this.running = false 
        }, 4500)
      }
    },
    handleAnimation(anim) {
      this.anim = anim
      anim.pause()
    },

    stop() {
      this.anim.stop()
    },

    play() {
      this.anim.play()
    },

    pause() {
      this.anim.pause()
    }
  }
}
